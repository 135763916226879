const gotopromotion = document.querySelectorAll('.gotopromotion') as NodeListOf<HTMLDivElement>;
gotopromotion.forEach(element => {
  element.addEventListener("click", function(ev) {
    const navElement = document.querySelector('.navbar') as HTMLElement | null;
    const stickyElement = document.querySelector('.sticky-bar') as HTMLElement | null;
    const navbarHeight = navElement? navElement.clientHeight :0;
    const stickyHeight = stickyElement ? stickyElement.clientHeight : 0;
    const navHeight = navbarHeight + stickyHeight + 70;
    const id = '#' + element.getAttribute('data-action');
    const formtyElement = document.querySelector(id) as HTMLElement;
    if (formtyElement) {
      const scrollTo = formtyElement.getBoundingClientRect().top + document.body.scrollTop - navHeight;
      document.body.scrollTo({
        top: scrollTo,
        behavior: 'smooth'
      });
    }
  });
});